<template>
    <a-menu :theme="MenuTheme"
            mode="inline"
            @click="MainMenuClick"
            @openChange="onOpenChange"
            :open-keys="MenuOpenKey"
            :defaultSelectedKeys="MenuCurrent"
            :defaultOpenKeys="MenuOpenKey">
        <template v-for="item in MenuData.MenuItem">
            <a-menu-item v-if="item.Type == 1||item.Type == 3"
                         :key="item.Key"
                         index="/OperationsModule/Operations/List">
                <a-icon :type="item.Icon||'setting'"></a-icon>
                <span>{{item.Text}}</span>
            </a-menu-item>
            <a-sub-menu v-if="item.Type == 2" :key="item.Key">
                <span slot="title">
                    <a-icon :type="item.Icon||'setting'"></a-icon>
                    <span> {{item.Text}}</span>
                </span>
                <template v-for="item2 in item.SubItem">
                    <a-menu-item v-if="item2.Type == 1||item2.Type == 3" :key="item2.Key" style="padding-left:36px;">
                        <span class="pad-l_1" >{{item2.Text}}</span>
                    </a-menu-item>
                </template>
            </a-sub-menu>
        </template>
    </a-menu>
</template>

<script>
    import Vue from "vue";
    import http from "@/Plugin/Http.js";
    export default {
        name: "Menu",
        props: {},
        data() {
            return {
                MenuData: {},
                MenuCurrent: [],
                MenuTheme: "dark",
                MenuOpenKey: []
            };
        },
        methods: {
            MainMenuClick: function (obj) {
                Vue.global.Store.MenuAction(obj.key);
            },
            GetCurrentSelect: function () {
                var CurrentMenu = Vue.global.Store.GetCurrentMenu();
                this.MenuCurrent = CurrentMenu.MenuCurrent;
                this.MenuOpenKey = CurrentMenu.MenuPath;
            },
            onOpenChange(openKeys) {
                const latestOpenKey = openKeys.find(key => this.MenuOpenKey.indexOf(key) === -1);
                this.MenuOpenKey = latestOpenKey ? [latestOpenKey] : [];
            },
        },
        mounted: function () {
        },
        created: function () {
            //console.log('menu1');
            var self = this;
            var op = {
                url: "/ShopModule/ShopManage/GetShopMenu",
                OnSuccess: function (res) {
                    var data = JSON.parse(JSON.stringify(res.data));
                    

                    if (data.CustomerType != "1") {
                        
                        for (var i = 0; i < data.MenuItem.length; i++) {
                            for (var j = 0; j < data.MenuItem[i].SubItem.length; j++) {
                                if (data.MenuItem[i].SubItem[j].Key == "M_3_4") {
                                    data.MenuItem[i].SubItem.splice(j, 1);                                    
                                }

                            }
                        }
                    }

                    var store = Vue.global.Store;


                    store.MenuInit(data);


                    self.MenuData = store.GetMenuData();
                    self.MenuTheme = store.GetMenuTheme();
                    self.GetCurrentSelect();
                }
            };
            http.Post(op);

          
        }
    };
</script>

