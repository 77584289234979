<template>
    <a-ConfigProvider :locale="this.$zhCN">
        <a-layout id="components-layout-demo-custom-trigger" style="height:100%;">
            <a-layout-sider v-model="collapsed" width="145">
                <!--collapsible-->
                <div class="logo" style="text-align:center;padding-left:0;">
                    <img src="/Image/yjlogo_white.svg" width="112" style="margin:0 auto;" />
                </div>
                <customMenu />
            </a-layout-sider>

            <a-layout>
                <a-layout-header v-if='showtop' style="background:#fff !important; padding: 0;color:#666;border-bottom:1px solid #ddd;">
                    <a-row>
                        <a-col :span="6">
                            <img v-if="SoftVersion=='0'" src="/Image/BZ-icon.svg" height="36" />
                            <img v-if="SoftVersion=='1'" src="/Image/ZY-icon.svg" height="36" />
                            <img v-if="SoftVersion=='2'" src="/Image/DZ-icon.svg" height="36" />
                        </a-col>
                        <a-col :span="4" :offset="14" style="text-align:right;padding-right:26px;">
                            <a-dropdown placement="bottomRight">
                                <span>
                                    <a-icon slot="icon" type="setting"></a-icon>
                                    {{CustomerName_Show}} 
                                    
                                </span>
                                <a-menu slot="overlay" @click="TopMenuClick">
                                    <a-menu-item key="EditPassword">
                                        <a-icon type="unlock"></a-icon>修改密码
                                    </a-menu-item>

                                    <a-menu-item key="BaseInfor">
                                        <a-icon type="unlock"></a-icon>基础信息
                                    </a-menu-item>
                                    <a-menu-item key="Feedback">
                                        <a-icon type="question-circle"></a-icon>产品反馈
                                    </a-menu-item>
                                    <a-menu-item key="Logout" style="color:#F11D1D">
                                        <a-icon type="logout"></a-icon>安全退出
                                    </a-menu-item>
                                </a-menu>
                            </a-dropdown>
                        </a-col>
                    </a-row>
                </a-layout-header>


                <a-layout-content style="overflow-y:auto;height:100%;">
                    <router-view />
                </a-layout-content>
            </a-layout>
        </a-layout>
    </a-ConfigProvider>
</template>
<script>
    import customMenu from "@/components/Menu.vue";
    import "ant-design-vue/dist/antd.css";
    
    import 'element-ui/lib/theme-chalk/index.css';
    import "@/assets/css/yjht.css";
    import 'moment/locale/zh-cn';
    import Vue from "vue";
    import ant from "ant-design-vue";
    import ElementUI from 'element-ui';
    import Account from "@/Plugin/Account.js";
    import zh_CN from 'ant-design-vue/lib/locale-provider/zh_CN';
    Vue.prototype.$zhCN = zh_CN;
    Vue.use(ant,ElementUI);
    export default {
        name: "Home",
        data() {
            return {
                CustomerName_Show: "",
                SoftVersion: "",
                collapsed: false,
                showtop: true
            };
        },
        methods: {
            TopMenuClick: function (obj) {
                if (obj.key == "Logout") {
                    Account.Logout();
                }
                if (obj.key == "BaseInfor") {
                    Vue.global.NavigateTo("EditBaseInfor");
                }


                if (obj.key == "EditPassword") {
                    Vue.global.NavigateTo("EditPassword");
                }
                if (obj.key == "Feedback") {
                    window.open("https://support.qq.com/products/18234?");
                }
            },
        },
        beforeRouteUpdate(to, from, next) {
            console.log(to.path)
            var that = this;
            if (to.path == "/Home/Index") {

                that.showtop = false;

            } else {
                that.showtop = true;
            }
            next();

        },
        beforeCreate: function () {


        },
        created: function () {
            var self = this;
            Account.GetCurrentUserInfo(function (CurrentUserInfo) {
                self.CustomerName_Show = CurrentUserInfo.CustomerName;
                self.SoftVersion = CurrentUserInfo.SoftVersion;
            });

        },
        mounted: function () {
            var that = this;
            if (that.$route.path == "/Home/Index") {

                that.showtop = false;

            } else {
                that.showtop = true;
            }

        },
        computed: {


        },
        components: {
            customMenu
        },
        beforeRouteLeave(to, from, next) {
            // 导航离开该组件的对应路由时调用
            // 可以访问组件实例 `this`
            Vue.global.Store.SetCache("LastPage", from.name);
            next();
        }
    };
</script>
<style scoped>
</style>